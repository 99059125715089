<section class="facsec2" [style.background]="background">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 [class]='styleTitle'>{{title}}
        </h2>
        <div class="row " >
          <div class="  col-lg-6 col-md-12 pl-5 mt-3" *ngFor='let item of Noticias' [routerLink]='item.systemTitle'>
            <a class='pointer ' [href]="item.systemTitle">
               <div class="row">
                <div class="col-6">
                  <img onerror="this.src='./assets/img/default.png'" [src]="item.foto">

                </div>
                <div class="col-6">
                  <div *ngIf='item.categoria' class='categoria'>{{item.categoria}}</div>
                  <p class='c-principal title fs-16 pointer'> {{item.title}}</p>
                  <p>{{item.summary}}</p>
                </div>
               </div>

            </a>
          </div>

          <!--<div class="cargar">
              <i class="fas fa-chevron-down"></i>
            </div>-->
        </div>
     
      </div>
    </div>
  </div>
</section>