<section class="possec1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="posg-cont">
                    <a [href]="'/es/'+item.systemTitle" *ngFor='let item of posgrados;index as i'
                        [ngClass]="(posgrados.length>1)?'posg-item '+item.class :'posg-item'">
                        <div>{{item.title}}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>