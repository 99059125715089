<h2>SERVICIOS</h2>
<div class='container-icons'>
    <a [href]="i.link" *ngFor='let i of data' class='item-icons'>
        <div class='div-img' *ngIf="i.path">
            <img [src]="i.path" onerror="this.src='./assets/img/none.png'" class='img-icon '
                alt="icono beneficio">
        </div>
        <div class='div-img' *ngIf="!i.path">
        </div>
        <p class="gray text-center mt-2 text-b">{{i.label}}</p>
    </a>

   
</div>