
<section class="facsec2" >
    <div class="container">
      <div class="row mx-4">
        <div class="col-lg-12">
            <div *ngIf='data' [id]="'text'+i"></div>

       
        </div>
      </div>
    </div>
  </section>