<h2>CONOCÉ TODAS NUESTRAS CARRERAS</h2>

<div class="panel-group" id="accordion">
    <div class="panel panel-default" *ngFor='let item of universidades;index as i'>
        <div class="panel-heading">
            <h4 class="panel-title">
                <div class="accordion-toggle " data-toggle="collapse" data-parent="#accordion" (click)='abrirPanel(i)'>
                    <h3 class="titulo sinbord ">{{item.title}}</h3>
                </div>
            </h4>
        </div>
        <div [id]="'collapse'+i" class="panel-collapse collapse in ">
            <div class="panel-body">
                <ul>
                    <li *ngFor='let subitem of datosCarreras[item.id-1]'><a class=''
                            [href]="language+subitem.linkCareer">{{subitem.title}}</a></li>

                </ul>
            </div>
        </div>
    </div>

</div>