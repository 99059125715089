import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RegistroCard2 } from 'src/app/shared/models/registroNoticia';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  @Input() id: string;
  @Input() styleTitle='azul';
  @Input() title='azul';
  @Input() type: string;
  @Input() vanue = false;
  @Input() numberCards = 4;
  @Input() data:any[];
  @Input() background='#eee';

  Noticias: RegistroCard2[] = [];
  language: any;

  constructor(public route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
  }

  ngOnInit(): void {
    if (this.background=='#ffffff') {
      this.styleTitle = "azul";
    }
    if(this.data){

      this.data.forEach(element => {
        let registro = new RegistroCard2();
        registro.title = element.title;
        registro.foto = element.path;
        registro.id = element.id;
        registro.categoria = element.categoryTitle;
        registro.link = element.path;
        registro.summary = element.summary;
        if (element.typeEntity == 'Post') {
          registro.systemTitle = this.language+'/noticias/' + element.systemTitle;
          // registro.systemTitle = this.language+'/noticias/' + element.typeEntity.toLowerCase() + '/' + element.systemTitle;
        } else {

          registro.systemTitle = '/'+this.language+'' + element.systemTitle;

        }
        this.Noticias.push(registro);
      });
  
    }
  
  }


}
