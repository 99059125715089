import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-buscador-carreras',
  templateUrl: './buscador-carreras.component.html',
  styleUrls: ['./buscador-carreras.component.scss']
})
export class BuscadorCarrerasComponent implements OnInit {

  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  carreras: Carrera[] = [];
  niveles: Carrera[] = [];
  areas: Carrera[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive = '1';
  pageSize = 15;
  spinner = true;
  filtro = false;
  language="es"
  constructor(public route: ActivatedRoute,private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      filtro: ['',]
    });

    this.servicesGeneral.getCarreras().subscribe(data => {
      this.resultados = [];
      this.carreras = [];
      this.areas = data.data[0].areaIngreso;
      this.regiones = data.data[0].region;
      this.niveles = data.data[0].careerTypeIngreso;


      data.data[0].career.forEach(element => {
        if(element.inicio){
          let inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          let title = element.title + ' | Inicio: ' + inicio;
          let date = (element.inicio) ? new Date(element.inicio.date) : null;
          this.carreras.push(new Carrera(element.id, title, element.linkCareer, date))
        }else{
          this.carreras.push(new Carrera(element.id, element.title, element.linkCareer))
        }
      });
      this.carreras.sort(function (a, b) { return (a.fecha && b.fecha) ? a.fecha.getTime() - b.fecha.getTime() : 0 });

      this.cambiarPagina(1);

      this.spinner = false;

    })
  }


  ngOnInit(): void {


  }


  filtrar(event) {
    this.spinner = true;
    var filtro = this.buscador.get('filtro').value;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.buscador.get('area').value;
    var resultados= document.getElementById("resultados");
    this.servicesGeneral.getCarreras(sede, nivel, area,filtro).subscribe(data => {
      this.resultados = [];
      this.carreras = [];
      if (data.data[0].career) {

        data.data[0].career.forEach(element => {
          if(element.inicio){
            let inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
            let title = element.title + ' | Inicio: ' + inicio;
            let date = (element.inicio) ? new Date(element.inicio.date) : null;
            this.carreras.push(new Carrera(element.id, title, element.linkCareer, date));
          }else{
            this.carreras.push(new Carrera(element.id, element.title, element.linkCareer));
          }
        });
      }
      if (sede == '' && nivel == '' && area == '' && filtro == '') {
        this.resultados = [];
        this.filtro = false
        resultados.style.display='none';

      } else {
        this.filtro = true
        resultados.style.display='block';
        this.cambiarPagina(1)
      }
     
    })


  }
  cambiarPagina(page) {
    this.pages = [];
    this.resultados = this.carreras.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = this.carreras.length / this.pageSize;
    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }
    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;

  }

  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  ngOnDestroy() {

  }
}
