<div class=" container card-title">
    <div class="float-left buttom-section">
        <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-perfil" src="./assets/img/perfil.png"
            alt="">
    </div>
    <div class=" title-section w-100">
        <div class="flex">
            <h2 class="azul inline">Perfil profesional y alcance del título</h2>
            <img onerror="this.src='./assets/img/default.png'" (click)="open()" class='icon pointer float-right inline'
                id="arrow" src="./assets/img/fic3.png" alt="">
        </div>
    </div>
    <div class="vr mb-3"></div>

    <div id="htmlPerfil" class="mt-5 ">

    </div>
</div>