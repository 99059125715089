import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { PosgradosRoutingModule } from './posgrados-routing.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { PosgradosListaComponent } from './components/posgrados-lista/posgrados-lista.component';
import { PosgradosComponent } from './pages/posgrados/posgrados.component';
import { CardsGeneralPosgradosComponent } from './components/cards-general-posgrados/cards-general-posgrados.component';
import { FormacionContinuiaComponent } from './components/formacion-continuia/formacion-continuia.component';
import { BuscadorPosgradosComponent } from './components/buscador-posgrados/buscador-posgrados.component';
import { CursosAbiertosJornadasExtensionComponent } from './pages/cursos-abiertos-jornadas-extension/cursos-abiertos-jornadas-extension.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AgendaGeneralPosgradosComponent } from './components/agenda-general-posgrados/agenda-general-posgrados.component';
import { SobreNosotrosPosgradosComponent } from './components/sobre-nosotros-posgrados/sobre-nosotros-posgrados.component';
import { OtrasCarrerasComponent } from './components/otras-carreras/otras-carreras.component';
@NgModule({
  declarations:[PosgradosComponent, PosgradosListaComponent, CardsGeneralPosgradosComponent, FormacionContinuiaComponent, BuscadorPosgradosComponent, CursosAbiertosJornadasExtensionComponent, AgendaGeneralPosgradosComponent, SobreNosotrosPosgradosComponent, OtrasCarrerasComponent],
  imports:[IvyCarouselModule, CommonModule,SharedModule,RouterModule,AngularMaterialModule, ],
  exports:[],
  schemas:[ CUSTOM_ELEMENTS_SCHEMA ],
})
export class PosgradosModule { }
