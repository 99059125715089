import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormContactoComponent } from '../form-contacto/form-contacto.component';

@Component({
  selector: 'app-formas-de-contacto-posgrados',
  templateUrl: './formas-de-contacto-posgrados.component.html',
  styleUrls: ['./formas-de-contacto-posgrados.component.scss']
})
export class FormasDeContactoPosgradosComponent implements OnInit {


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openModal(): void {
    let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container' ,data: { curso:null }});
    const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  windowOpen(url) {
    window.open(url)
  }
}
