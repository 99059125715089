<section class="subheader min doble-m" *ngIf="dataSlider.length>0">
  <app-slider-posgrados [data]='dataSlider' [datos]='datos'> </app-slider-posgrados>
</section>
<!--<section class="facsec2 min  " style="padding: 20px 0px 0px 0px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <span class="idioma-seleccionado " *ngIf="language == 'es'"> Español </span>
        <span class="idioma " *ngIf="language != 'es'"> Español </span>
        |
        <span class="idioma" *ngIf="language != 'en'"> Inglés </span>
        <span class="idioma-seleccionado" *ngIf="language == 'en'"> Inglés </span>
      </div>
    </div>
  </div>
</section>-->
<section class="facsec2 min  " *ngIf="datos">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card-title" [ngStyle]="{'background': color}">
          <h1 class="blanco strong">{{datos.title}}</h1>
          <span id="span-card" class="blanco strong mt-2">
            {{datos.subtitle}}
          </span><br>
          <!--<div class="vr mb-3"></div>-->

          <span class='text-center link-section'>
            <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-university"
                src="./assets/img/university-solid.png" alt="">
            <a [href]="item.link" class="blanco" *ngFor='let item of datos.region'>{{item.title}} </a>
        </span>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="facsec2 ">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <app-info-posgrado-card *ngIf="datos && datos.studyPlanItems"  [color]="color" [title]='datos.systemTitle' [data]='datos.studyPlanItems'>
        </app-info-posgrado-card>
      </div>
      <div class="col-lg-4">
        <app-info-column-posgrado *ngIf="datos " [color]="color" [datos]='datos'></app-info-column-posgrado>
      </div>
    </div>
  </div>
</section>
<section class="facsec2 min  ">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <!--[ngStyle]="{'background': color}"-->
        <div class="card-banner pointer"  >
          <a *ngIf="datos && datos.link" [href]="datos.link" Target="_blank" >
            <h1 class="blanco strong">
              PROPUESTAS ACADÉMICAS RELACIONADAS
            </h1>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>