import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
@Component({
  selector: 'app-posgrados-general-uno',
  templateUrl: './posgrados-general-uno.component.html',
  styleUrls: ['./posgrados-general-uno.component.scss']
})
export class PosgradosGeneralUnoComponent implements OnInit {
  idNuestroInstituto: string;
  idAgenda: string;
  idCarreras: string;
  datos: any;
  agenda: any;
  items: any[] = [];
  region: any;
  htmlAranceles: string;
  htmlAdmision: string;
  htmlInscripcion: string;
  htmlEstudiar: string;
  seccion: string;
  id: string;
  language: any;
  titleLink: any;
  currentRoute: string;
  carrer: string;
  venue: string;
  carrertype: string;
  regionURL: string;
  formLink: string;
  dataSlider: any[] = [];
  htmlAutoridades:string;
  color="#013974";
  formEspecial = ['1325', '1326', '1327', '1326', '466', '469', '472', '475', '478', '481'];
  constructor(public dialog: MatDialog, private services: GeneralService, private aRouter: ActivatedRoute, private router: Router) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
      this.titleLink = params['title'];
      this.seccion = this.aRouter.snapshot.params['seccion']
      this.id = this.aRouter.snapshot.params['id']
    });



    this.services.getCarreraByTitle(this.id, this.seccion).subscribe(data => {
      if (data.data) {
        this.datos = data.data[0];
        this.color=this.datos.color;
        this.dataSlider.push({
          id: 1,
          pathMobile: this.datos.pathMobile,
          path: this.datos.path
        });
      } else {
        router.navigate(['404']);
      }

    })


  }



  ngOnInit(): void {
  }
  

}
