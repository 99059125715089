import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { ConsultaRequest } from 'src/app/shared/models/consultaRequest';
import { Patters } from 'src/app/shared/models/patters';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-contacto',
  templateUrl: './form-contacto.component.html',
  styleUrls: ['./form-contacto.component.scss'],
  providers: []
})
export class FormContactoComponent implements OnInit {
  @Input() carrer: string;
  @Input() type = "ingresa";
  @Input() venue: string;
  @Input() tel: Boolean = false;
  curso: any;
  formDatos: FormGroup;
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { curso: string },
    private form: FormBuilder, private services: GeneralService) {
    this.curso = data.curso;
    this.formDatos = this.form.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      region: ['', [Validators.required]],
      phone: ['', [Validators.pattern(Patters.OnlyNumber)]],
      message: [''],
    });
  }

  ngOnInit(): void {
  }

  consultar() {
    let datos = new ConsultaRequest();
    datos.email = this.formDatos.get('email').value;
    datos.name = this.formDatos.get('name').value;
    datos.region = this.formDatos.get('region').value;
    datos.isHome = false;
    datos.message = this.formDatos.get('message').value;
    datos.career = this.carrer;
    datos.phone = this.formDatos.get('phone').value;
    datos.curso = this.curso ;
    datos.venue = this.venue;
    if (datos.venue && datos.career) {
      this.consultarEmblue(datos);
    }
    this.services.postConsulta(datos).then(function (response) {
      return response.json();
    }).then(res => {
      if (res.status == 1) {
        this.closeModal.emit("")
        this.formDatos.reset()
        Swal.fire(
          'La consulta fue enviada con exito!',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'La consulta fallo , lo sentimos',
          '',
          'error'
        )

      }
    }
    )


  }
  consultarEmblue(datos: ConsultaRequest) {
    let atributos = (
      {
        career: carrera,
        name: nombre,
        phone: whatsapp,
        region: sede
      }
    ) => ({ carrera, nombre, whatsapp, sede });
    let eventoEmblue = {
      eventName: 'form_derecho',
      email: datos.email,
      attributes: atributos(datos)
    };
    if (datos.venue) {
      switch (datos.venue) {
        case "facultad-de-derecho":
          eventoEmblue.eventName = "form_derecho"
          break;
        case "facultad-de-psicologia-y-psicopedagogia":
          eventoEmblue.eventName = "form_psico"
          break;
        case "facultad-de-ciencias-medicas":
          eventoEmblue.eventName = "form_cs.medicas"
          break;
        case "facultad-de-ingenieria-y-ciencias-agrarias":
          eventoEmblue.eventName = "form_ingeniería"
          break;
        case "facultad-de-artes-y-ciencias-musicales":
          eventoEmblue.eventName = "form_musica"
          break;
        case "facultad-de-filosofia-y-letras":
          eventoEmblue.eventName = "form_filo"
          break;
        case "facultad-de-ciencias-economicas":
          eventoEmblue.eventName = "form_economicas"
          break;
        case "facultad-de-ciencias-sociales":
          eventoEmblue.eventName = "form_sociales"
          break;

        default:
          eventoEmblue.eventName = "form_derecho"
          break;
      }
    }

    console.log(eventoEmblue)
    if (this.type == "posgrados") {
      this.services.postEmblueFormPosgrados(eventoEmblue).subscribe(
        (data) => console.log({ message: ' Form emblue Posgrados', data: data }),
        error => console.log(error)
      )
    } else {
      this.services.postEmblueForm(eventoEmblue).subscribe(
        (data) => console.log({ message: 'Form emblue ', data: data }),
        error => console.log(error)
      )
    }

  }

}


