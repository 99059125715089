<div class=" container card-title">
  <div class="float-left buttom-section">
    <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-estudiar" src="./assets/img/estudiar.png"
      alt="">
  </div>
  <div class="title-section w-100">
    <h2 class="azul">¿Por qué estudiar en UCA?</h2>
  </div>
  <div class="vr mb-3"></div>

   <div id="htmlPorqueEstudiar">

   </div>
</div>