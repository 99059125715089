<section class="subheader doble-m">
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="datos.path">
    <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="datos.pathMobile">
    <div class="texto-s container">
        <div class='row'>
            <div class='col'>
                <h1 class="azul">{{datos.title}}</h1>
            </div>
        </div>
        <div class='container-carousel-header w-100'>
            <div class="carousel-cell-header " *ngFor="let item of  itemCarrouselHeader;">
                <div class='content-carousel-header'>
                    <a [href]="url(item)">{{item.title}}</a>
                </div>
            </div>
        </div>
    </div>

</section>

<section class="agsec1" *ngIf='datos.type == "news_list"'>
    <div class="container">

        <div class='row'>
            <div class='col'>
                <p _ngcontent-c3="" class="fs-16 c-principal breadcump">
                    <ng-container *ngIf='datos.parent'> 
                        <span _ngcontent-c3="" id="breadcump">
                            <a [href]="language+'/'+datos.parent[0].systemTitle" class="c-principal">
                                <strong *ngIf='datos.parent'>{{datos.parent[0].title}} &gt; </strong>
                            </a>
                            {{datos.title}}
                        </span>
                    </ng-container>

                    <ng-container *ngIf='!datos.parent'> 
                        <span _ngcontent-c3="" id="breadcump">
                            <a [href]="backUrl()" class="c-principal">
                                <strong>{{datos.venueTitle}} &gt; </strong>
                            </a>
                            {{datos.title}}
                        </span>
                    </ng-container>                    
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
                <div>
                    <div class="list-general">
                        <div *ngFor='let item of datos.newslist' class="list-general-item">
                            <p class='fs-1-25-em negro'>{{item.year}}</p>
                            <div *ngFor='let month of item.month'>
                                <p class='fs-1-25-em negro'> {{convertir(month.mes)}}</p>

                                <div class="list-general" *ngFor='let info of month.info'>
                                    <a class='pointer list-general-item' [href]="language+info.systemTitle">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-record" viewBox="0 0 16 16">
                                            <path
                                                d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10z" />
                                        </svg>
                                        {{info.title}}- <span>{{info.createdAt.date| date: 'M/d/yy'}}</span>
                                    </a>
                                </div>

                            </div>

                            <br>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-4" *ngIf='itemsInfo.length > 0'>

                <ul class="list-group">
                    <li class="list-group-item active ">Más información
                    </li>
                    <li *ngFor='let item of itemsInfo' class="list-group-item "><a [href]="url(item)">{{item.title}}</a>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</section>
<section class="agsec1">
    <div class="container">
        <div class='row'>
            <div class='col'>
                <p _ngcontent-c3="" class="fs-16 c-principal breadcump" *ngIf='datos.parent'>
                    <span _ngcontent-c3="" id="breadcump">
                        <a *ngFor='let parent of parents' [href]="language+'/'+parent.systemTitle" class="c-principal">
                            <strong *ngIf='datos.parent'> {{parent.title}} &gt; </strong>
                        </a>
                        {{datos.title}}
                    </span>
                </p>
                <p _ngcontent-c3="" class="fs-16 c-principal breadcump" *ngIf='!datos.parent'>
                    <span _ngcontent-c3="" id="breadcump">
                        <a [href]="backUrl()" class="c-principal">
                            <strong > {{datos.title}} &gt; </strong>
                        </a>
                    </span>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <ng-container *ngFor="let item of datos.autorities">
                    <ng-container class='desk'>
                        <div class="row desk" >
                            <div class="col-md-2">
                                <img onerror="this.src='./assets/img/default.png'" src="{{item.path}}">
                            </div>
                            <div class="col-md-6 ml-5" style="color: #1e3e6f">
                                <p><b>{{item.name}}</b></p>
                                <p><span>{{item.place}}</span></p>
                                <p>{{item.venue}}</p>
                                <div *ngIf="item.email != null || item.phone != null">
                                    <p *ngIf="item.email != null" style='line-height: 16px;font-size:12px'>Email:
                                        {{item.email}}</p>
                                    <p *ngIf="item.phone != null" style='line-height: 16px;font-size:12px'>Teléfono:
                                        {{item.phone}}</p>
                                </div>
                                <ng-container *ngIf="item.attachItems">
                                    <ng-container *ngFor="let attach of item.attachItems">
                                        <p><a class="c-principal" href="{{attach.href}}" target="_blank">Ver CV</a></p>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>


                    <ng-container calss='mob'>
                        <div class="row mob">
                            <div class="col-md-2">
                                <img onerror="this.src='./assets/img/default.png'" style="height:130px;width:130px;" src="{{item.path}}">
                            </div>
                            <div class="col-md-2 ml-8" style="color: #1e3e6f">
                                <br>
                                <p><b>{{item.name}}</b></p>
                                <p><span>{{item.place}}</span></p>
                                <p>{{item.venue}}</p>
                                <div *ngIf="item.email != null || item.phone != null">
                                    <p *ngIf="item.email != null" style='line-height: 16px;font-size:12px'>Email:
                                        {{item.email}}</p>
                                    <p *ngIf="item.phone != null" style='line-height: 16px;font-size:12px'>Teléfono:
                                        {{item.phone}}</p>
                                </div>
                                <ng-container *ngIf="item.attachItems">
                                    <ng-container *ngFor="let attach of item.attachItems">
                                        <p><a class="c-principal" href="{{attach.href}}" target="_blank">Ver CV</a></p>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <br><br>
                </ng-container>
                <div id='body'></div>
                <div *ngFor='let link of links' style='margin-bottom:1.5em;'>
                    <h2 style='margin-bottom: 0em;'>{{link.title}}</h2>
                    <div class="list-general">
                        <div *ngFor='let item of link.linkItems' class="list-general-item">

                            <a *ngIf='!item.child' class='pointer' [href]="url(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-record" viewBox="0 0 16 16">
                                    <path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10z" />
                                </svg>
                                {{item.title}}
                            </a>

                            <a class='pointer ml-5' *ngIf='item.child' [href]="url(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-record" viewBox="0 0 16 16">
                                    <path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10z" />
                                </svg>
                                {{item.title}}
                            </a>
                            <br>
                        </div>

                    </div>
                </div>

                <section style='padding-top:0px; margin-top: 9.5rem;' class="" *ngIf='archivo'>
                    <div class="container">
                
                        <div class="row">
                            <div class="col-lg-12" style="padding-left: 0;">
                                <h3 _ngcontent-c15="" class="c-principal fs-32 bold ">Archivos: </h3>
                                <ng-container *ngFor="let item of archivo" >
                                    <div _ngcontent-c15="" class="row">
                                        <div _ngcontent-c15="" class="col-md-6">
                                            <a _ngcontent-c15=""
                                                [href]="item.href">
                                                <div _ngcontent-c15="" class="row">
                                                    <div _ngcontent-c15="" class="col-md-2">
                                                        <img onerror="this.src='./assets/img/default.png'" _ngcontent-c15="" class="img-fluid mt-4"
                                                            [src]="item.path">
                                                    </div>
                                                    <div _ngcontent-c15="" class="col-md-10">
                                                        <p _ngcontent-c15="" class="c-principal fs-21 bold mt-3">{{item.title}}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                    
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
            <div class="col-lg-4" *ngIf='itemsInfo && itemsInfo.length > 0'>
                <ul class="list-group">
                    <!-- acaaa-->
                    <li class="list-group-item active ">Más información
                    </li>
                    <li *ngFor='let item of itemsInfo' class="list-group-item "><a [href]="url(item)">{{item.title}}</a>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</section>

<!-- <section style='padding-top:0px!important;' class="" *ngIf='archivo'>
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <h3 _ngcontent-c15="" class="c-principal fs-32 bold ">Archivos: </h3>
                <div _ngcontent-c15="" class="row">
                    <div _ngcontent-c15="" class="col-md-6">
                        <a _ngcontent-c15=""
                            href="http://wadmin.uca.edu.ar/public/20180416/1523887911_2017-Universidad-Historia-de-la-Universidad-Catolica-Argentina.pdf">
                            <div _ngcontent-c15="" class="row">
                                <div _ngcontent-c15="" class="col-md-2">
                                    <img onerror="this.src='./assets/img/default.png'" _ngcontent-c15="" class="img-fluid mt-4"
                                        src="http://wadmin.uca.edu.ar/public/filters/icon/images/pdf.png">
                                </div>
                                <div _ngcontent-c15="" class="col-md-10">
                                    <p _ngcontent-c15="" class="c-principal fs-21 bold mt-3">Historia de la Universidad
                                        Católica Argentina
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section> -->