<h2>ELEGí TU CARRERA </h2>
<h5 class="azul">Encontrá toda la información sobre la carrera que te interesa</h5>
<div style='background-color: #003567 !important;padding: 5%;'>
    <form [formGroup]="buscador" class="form-group" id="busqueda"
        style='margin-bottom:0px;justify-content: space-between;'>

        <div style="display: flex;
        flex-wrap: wrap;">

            <input matInput class="form-control inputPalabraClave" formControlName="filtro" placeholder="Buscar carrera, temática y más" (keyup)="filtrar($event)">

            <select id="nivel" formControlName="nivel" (change)="filtrar($event)" class="form-control">
                <option value=''>Elegir nivel...</option>
                <option *ngFor='let item of niveles' [value]='item'>{{item}}</option>
            </select>
            <select id="sedes" formControlName="sede" (change)="filtrar($event)" class="form-control ">
                <option value=''>Elegir sede...</option>
                <option *ngFor='let item of regiones' [value]='item.id'>{{item.title}}</option>
            </select>
            <select id="area" formControlName="area" (change)="filtrar($event)" class="form-control">
                <option value=''>Elegir area...</option>
                <option *ngFor='let item of areas' [value]='item.id'>{{item.title}}</option>
            </select>
           

        </div>
        <!-- 
        <div class='text-center'>
            <a (click)='filtrar()' class="btn btn-primary ml-2">Buscar</a>

        </div> 
        -->


    </form>
</div>
<div class="resultados card p-2" id="resultados">
    <ul *ngIf='!spinner'>
        <li class='mt-3 fs-21' *ngFor='let carrera of resultados'><a [href]="language +carrera.link"> {{carrera.nombre}} </a></li>

    </ul>
    <div class="d-flex justify-content-center" *ngIf='spinner && filtro'>
        <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0'>
        <div (click)='cambiarPagina(page)' *ngFor="let page of  pages" class="number "
            [ngClass]='(numberActive == page)?"active":""'>{{page}}</div>
    </div>

    <div *ngIf=' resultados.length == 0 && !spinner && filtro'  class='p-3 text-center'>
        <div class="alert alert-primary text-center" role="alert">
            No hay resultados
        </div>
    </div>
</div>