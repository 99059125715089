import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-busqueda-avanzada',
  templateUrl: './busqueda-avanzada.component.html',
  styleUrls: ['./busqueda-avanzada.component.scss']
})
export class BusquedaAvanzadaComponent implements OnInit {
  type = '';
  palabra = '';
  language = ''
  lista = []
  spinner = true;
  constructor(public router: ActivatedRoute, private generalService: GeneralService) {
    let url = window.location.href;
    this.type = this.router.snapshot.params['data'];
    this.language = this.router.snapshot.params['language'];
    let index = url.indexOf("#")
    this.palabra = url.slice((index + 1), url.length).replace('%20',' ')
    if (this.type == 'ingreso') {
      this.generalService.byPalabaClave("?locale=" + this.language + "&keyword=" + this.palabra + "&notGraduateDegree=0").subscribe(res => {
        this.lista = []
        this.lista = res.data[0].career
        this.spinner = false;
      })
    }

    if (this.type == 'posgrados') {
      this.generalService.byPalabaClave("?locale=" + this.language + "&keyword=" + this.palabra + "&notGraduateDegree=1").subscribe(res => {
        this.lista = []
        this.lista = res.data[0].career
        this.spinner = false;
      })
    }

    /*if (this.type == 'cursos-de-educacion-continua') {
      this.generalService.byPalabaClave("?locale=" + this.language + "&keyword=" + this.palabra + "&notGraduateDegree=1").subscribe(res => {
        this.lista = []
        this.lista = res.data[0].career
        this.spinner = false;
      })
    }*/
  }

  ngOnInit(): void {
  }

}
