<section class="subheader sub-video doble-m">
  <img onerror="this.src='./assets/img/default.png'" *ngIf='path' class="img-title w-100 desk img-desk" [src]="path">
  <img onerror="this.src='./assets/img/default.png'" *ngIf='pathMob' class="img-title w-100 mob img-mob" [src]="pathMob">

 <!--OVC-bqQ8CVE-->
  <iframe  *ngIf='link' allow="loop; autoplay; encrypted-media" allowfullscreen="" frameborder="0"
  [src]="getVideoIframe(link)">
  </iframe>
  <div class="texto-s container texto-s-center">
    <!--<a href="es/ingreso/elegi-tu-carrera" class="btn btn-primary">ELEGÍ TU CARRERA</a>-->
    <div class='container-carousel-header w-100'>
      <div *ngFor='let boton of botones' class="carousel-cell-header m-2">
        <div *ngIf='boton.estado' [style.background]="boton.background" class='content-carousel-header'>
          <a [href]="boton.link">{{boton.label}}</a>
        </div>

      </div>

    </div>
  </div>





</section>
<section class="logsec1" id='elegi-tu-carrera'>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <app-buscador-carreras></app-buscador-carreras>
      </div>
    </div>
  </div>
</section>
<section class="logsec1">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <app-todas-las-carreras></app-todas-las-carreras>
      </div>
    </div>
  </div>
</section>

<section class="logsec3" *ngIf='benefitsButtons'>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <app-icons *ngIf='benefitsButtons' [data]='benefitsButtons'></app-icons>
      </div>
    </div>
  </div>
</section>
<section class="logsec1">

  <app-formas-de-contacto></app-formas-de-contacto>

</section>
<!--<ng-container #componenteDinamico2></ng-container>
<ng-container #componenteDinamico3></ng-container>
<ng-container #componenteDinamico4></ng-container>
<ng-container #componenteDinamico5></ng-container>
<ng-container #componenteDinamico6></ng-container>
<ng-container #componenteDinamico7></ng-container>
<ng-container #componenteDinamico8></ng-container>
<ng-container #componenteDinamico9></ng-container>
<ng-container #componenteDinamico10></ng-container>
<ng-container #componenteDinamico11></ng-container>
<ng-container #componenteDinamico12></ng-container>-->