<h2>Posgrados</h2>
<h5 class="azul">Encontrá toda la información sobre el posgrado que te interesa
</h5>
<div class="pos-busq">
    <form [formGroup]="buscador" class="form-group">
        <div class="input-group" id="busqueda">
            <i class="fas fa-search"></i>
            <input type="text" (keyup)='filtrarPorNombre()' formControlName="busqueda" class="form-control"
                placeholder="Buscar...">
        </div>
        <select id="sedes" (onChange)='buscar()' formControlName="sede" class="form-control">
            <option value=''>Elegir sede...</option>
            <option *ngFor='let region of regiones' [value]='region.id'>{{region.title}}</option>
        </select>

        <select id="areas" (onChange)='buscar()' formControlName="area" class="form-control">
            <option value=''>Elegir area...</option>
            <option *ngFor='let area of areas' [value]='area.id'>{{area.title}}</option>
        </select>

        <select id="tipos" (onChange)='buscar()' formControlName="sede" class="form-control">
            <option value=''>Elegir tipo...</option>
            <option *ngFor='let tipo of tipos' [value]='tipo.id'>{{tipo.title}}</option>
        </select>
    </form>
</div>
<div class="resultados">
    <ul>
        <li *ngFor='let carrera of resultados'><a [href]='carrera.link'> {{carrera.nombre}} </a></li>

    </ul>
</div>