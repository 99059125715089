import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-buscador-posgrados',
  templateUrl: './buscador-posgrados.component.html',
  styleUrls: ['./buscador-posgrados.component.scss']
})
export class BuscadorPosgradosComponent implements OnInit {

  regiones:string[]=[];
  carreras:Carrera[]=[];
  tipos:Carrera[]=[];
  areas:Carrera[]=[];
  resultados:Carrera[]=[];
  buscador:FormGroup;
  
  constructor(private servicesGeneral:GeneralService,private form:FormBuilder) {
   this.buscador = this.form.group({
      busqueda:['', [Validators.required]],
      sede:['', [Validators.required]],
      area:['', [Validators.required]],
      tipo:['', [Validators.required]],
    });
   }

  ngOnInit(): void {
    var region=this.buscador.get('sede').value;
    var area=this.buscador.get('area').value;
    var tipo=this.buscador.get('tipo').value;
    this.servicesGeneral.getPosgrados(region,area,tipo).subscribe(data=>{
      data.data[0].career.forEach(element => {
        this.carreras.push(new Carrera(element.id,element.title,element.linkCareer))
        this.resultados.push(new Carrera(element.id,element.title,element.linkCareer))
        this.servicesGeneral.getEntitylist('285','posgrado',false).subscribe(areas=>{
          this.areas=areas.data[0].entityList[0].entity;
        })
      });
      this.regiones=data.data[0].region;

    })
  }


  ngOnDestroy(){
   
  }
  buscar(){
    var region=this.buscador.get('sede').value;
    var area=this.buscador.get('area').value;
    var tipo=this.buscador.get('tipo').value;
    this.servicesGeneral.getPosgrados(region,area,tipo).subscribe(data=>{
      this.carreras=[]
      this.resultados=[]
      console.log('data.data[0].career')

      data.data[0].career.forEach(element => {

        this.carreras.push(new Carrera(element.id,element.title,element.linkCareer))
        this.resultados.push(new Carrera(element.id,element.title,element.linkCareer))
        this.servicesGeneral.getEntitylist('285','posgrado',false).subscribe(areas=>{
          this.areas=areas.data[0].entityList[0].entity;
        })
      });
      this.regiones=data.data[0].region;

    })
  }

  filtrarPorNombre(){
    var valor=this.buscador.get('busqueda').value;
    if(valor){
      this.resultados=[];
      this.resultados=this.carreras.filter(d=>d.nombre.toLowerCase().includes(valor.toLowerCase().trim()))
    }else{
      this.resultados=this.carreras;
    }
  }
}
