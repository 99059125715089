import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfil-profesional',
  templateUrl: './perfil-profesional.component.html',
  styleUrls: ['./perfil-profesional.component.scss']
})
export class PerfilProfesionalComponent implements OnInit {

  @Input() htmlPerfil: string;
  constructor() { }

  ngOnInit(): void {
    document.getElementById('htmlPerfil').innerHTML = this.htmlPerfil;
    document.getElementById("htmlPerfil").style.visibility = "hidden";
    document.getElementById("htmlPerfil").style.height = "0px";
  }

  open() {
    var status = document.getElementById("htmlPerfil").style.visibility;
    if (status == "hidden") {
      document.getElementById("htmlPerfil").style.visibility = "visible";
      document.getElementById("htmlPerfil").style.height = "auto";

    } else {
      document.getElementById("htmlPerfil").style.visibility = "hidden";
      document.getElementById("htmlPerfil").style.height = "0px";

    }

  }
}
