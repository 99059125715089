<div class="container-header" id='header-container'>

    <div class="desk">
        <span class="logo">
            <a href="javascript:void(0);" class="icon" (click)="myFunction()">
                <div>

                </div>
                <div>

                </div>
                <div>

                </div>
            </a>
            <a href="/"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/logo.png"></a>
        </span>
        <span>
            <img class="img-logo-2" src="../../../../../assets/img/LOGO-RaicesFrutos-web-02.svg" width="190" />

        </span>
    </div>

    <div class="container-mob mob">

        <span class="logo">
            <div class="flex">
                <a href="javascript:void(0);" class="icon icon-menu " (click)="myFunction()">
                    <div>

                    </div>
                    <div>

                    </div>
                    <div>

                    </div>
                </a>
                <a class="a-logo" href="/"><img onerror="this.src='./assets/img/default.png'"
                        src="../../../../../assets/img/logo.png"></a>
            </div>
            <img class="img-logo-2" src="../../../../../assets/img/LOGO-RaicesFrutos-web-02.svg" />
        </span>

        <div class="container-icons-redes  ">
            <div class="redes-mob ">
                <a href="https://es-la.facebook.com/universidad.catolica.argentina/" target="_blank"><img
                        onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/fb.png"></a>
                <a href="https://www.youtube.com/user/UCAarg" target="_blank"><img
                        onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/yt.png"></a>
                <a href="https://www.instagram.com/univcatolicaargentina/?hl=es" target="_blank"><img
                        onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/ig.png"></a>
                <a href="https://www.linkedin.com/school/pontificia-universidad-cat%C3%B3lica-argentina/"
                    target="_blank"><img onerror="this.src='./assets/img/default.png'"
                        src="../../../../../assets/img/ln.png"></a>
            </div>
        </div>
    </div>
    <nav class='desk'>
        <div id="header">
            <ul class="nav">
                <li *ngFor='let parent of menu;index as i' class='li-parent' [id]='"parent-"+parent.id'>
                    <a class='item-parent' role="button" aria-expanded="false">{{parent.title}}</a>
                    <ul class='ul-menu scroll2'>
                        <li class="dropdown-h " *ngFor='let child of parent.menuItem;index as i' [id]='child.id'>
                            <a class='label-menu' *ngIf='child.externalLink' style='cursor: pointer;'
                                [href]="child.link">{{child.title}} </a>
                            <a class='label-menu' *ngIf='!child.externalLink' style='cursor: pointer;'
                                [href]="language+child.linkSystem">{{child.title}} </a>
                            <div>
                                <ul class="dropdown-menu-h ul-menu scroll" [id]='"index-"+i'
                                    [ngClass]='"child class-"+child.id'
                                    *ngFor='let itemMenu of child.menuItem;index as i'>
                                    <li *ngFor='let subchild of itemMenu'
                                        [ngClass]='(subchild.link==null)? "fac-sed":""'>
                                        <a class='label-menu-style' *ngIf='subchild.externalLink'
                                            [href]="subchild.link">{{subchild.title}}</a>
                                        <a class='label-menu-style' *ngIf='!subchild.externalLink'
                                            [href]="'es'+subchild.linkSystem">{{subchild.title}}</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>

                <li class='li-parent'>
                    <a class='item-parent' role="button" aria-expanded="false">CONTACTANOS</a>
                    <ul class="dropdown-menu ul-menu" id='menu-contacto'>
                        <li class="dropdown-h contactdrop">
                            <div class="container">
                                <!--<button (click)='cerrarContacto()' type="button" class="close float-right"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>-->
                                <div class="row">
                                    <!--<div class="col-lg-4">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam venenatis cursus
                                            pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia
                                            nostra, per inceptos himenaeos. Quisque molestie euismod magna, vel semper lacus
                                            accumsan et. </p>
                                    </div>-->
                                    <div class="col-lg-12" id='contacto'>
                                        <h2>Contactanos</h2>
                                        <app-contacto-shell></app-contacto-shell>
                                    </div>
                                </div>
                            </div>

                        </li>

                    </ul>
                </li>
            </ul>

        </div>

        <div class="redes">
            <a href="https://es-la.facebook.com/universidad.catolica.argentina/" target="_blank"><img
                    onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/fb.png"></a>
            <a href="https://www.youtube.com/user/UCAarg" target="_blank"><img
                    onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/yt.png"></a>
            <a href="https://www.instagram.com/univcatolicaargentina/?hl=es" target="_blank"><img
                    onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/ig.png"></a>
            <a href="https://www.linkedin.com/school/pontificia-universidad-cat%C3%B3lica-argentina/"
                target="_blank"><img onerror="this.src='./assets/img/default.png'"
                    src="../../../../../assets/img/ln.png"></a>
        </div>
        <div class="search desk">
            <input (keyup.enter)='buscarWeb()' id='buscadorWeb' type="text" placeholder="Buscar...">

        </div>
    </nav>

    <nav class='mob'>
        <ul class="topnav" id="myTopnav">
            <div id="header-mob">
                <ul class="nav-mob ">
                    <li *ngFor='let parent of menu' class='li-parent-mob'>
                        <a class='item-parent' [id]='parent.title' role="button" aria-expanded="false">{{parent.title}}
                            <i class="fas fa-chevron-down" style='float:right;'></i>
                        </a>
                        <ul class='lu-mob  ul-menu' [id]='"lu-"+parent.title'>
                            <li class="dropdown-h-mob gray" *ngFor='let child of parent.menuItem;index as ic'>
                                <div class="div-arrow">
                                    <a *ngIf="child.menuItem[0].length == 0"
                                        [href]="(child.externalLink) ? child.link : language+child.link"
                                        class='mob label-menu gray' style='cursor: pointer;' role="button"
                                        aria-expanded="false">{{child.title}}

                                    </a>
                                    <a [href]="(child.externalLink) ? child.link : language+child.link"
                                        *ngIf="child.menuItem[0].length > 0" class='mob label-menu gray'
                                        style='cursor: pointer;' role="button" aria-expanded="false">{{child.title}}
                                    </a>
                                    <i (click)="arrow(child.title + ic)" *ngIf='child.menuItem[0].length > 0' [id]="child.title + ic"
                                        class="arrow-sub fas fa-chevron-down " style='float:right;color:#003567;'></i>
                                </div>
                                <ul class='lu-mob ul-menu' [id]="'lu-'+ child.title + ic">
                                    <li *ngFor='let subchild of child.menuItem[0];index as im'
                                        [ngClass]='(subchild.link==null)? "fac-sed":""'>
                                        <a class='label-menu-style' *ngIf='subchild.externalLink'
                                            [href]="subchild.link">{{subchild.title}} </a>
                                        <a class='label-menu-style' *ngIf='!subchild.externalLink'
                                            [href]="'es'+subchild.linkSystem">{{subchild.title}}</a>
                                    </li>
                                    <li *ngFor='let subchild of child.menuItem[1]'
                                        [ngClass]='(subchild.link==null)?"fac-sed":""'>
                                        <a class='label-menu-style' *ngIf='subchild.externalLink'
                                            [href]="subchild.link">{{subchild.title}}</a>
                                        <a class='label-menu-style' *ngIf='!subchild.externalLink'
                                            [href]="'es'+subchild.linkSystem">{{subchild.title}}</a>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </li>

                    <li class=" contacto">
                    <li class='li-parent-mob'><a class='item-parent' role="button" aria-expanded="false">CONTACTANOS</a>

                        <ul class="dropdown-menu">
                            <li class="dropdown-h contactdrop">
                                <div class="container">
                                    <button (click)='cerrarContacto()' type="button" class="close float-right"
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div class="row">
                                        <!--<div class="col-lg-4">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam venenatis cursus
                                                pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia
                                                nostra, per inceptos himenaeos. Quisque molestie euismod magna, vel semper lacus
                                                accumsan et. </p>
                                        </div>-->
                                        <div class="col-lg-12" id='contacto'>
                                            <h2>Contactanos</h2>
                                            <app-contacto-shell></app-contacto-shell>
                                        </div>
                                    </div>
                                </div>

                            </li>

                        </ul>
                    </li>
                    <li>


                    </li>
                </ul>

            </div>

        </ul>

        <div class="search desk">
            <input (keyup.enter)='buscarWeb()' id='buscadorWeb' type="text" placeholder="Buscar...">

        </div>
    </nav>

    <div class="search mob">

        <input (keyup.enter)='buscarMob()' id='buscadorMob' type="text" placeholder="Buscar...">
    </div>
</div>