import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-dos',
  templateUrl: './general-dos.component.html',
  styleUrls: ['./general-dos.component.scss']
})
export class GeneralDosComponent implements OnInit {
  //general con lista 
  datos: any;
  itemsParent: any[] = [];
  links: any[];
  titleList: string;
  styleColor = '#1e3e6f';
  language: any;
  constructor(private aRouter: ActivatedRoute, private services: GeneralService) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    var url = window.location.href;
    var id = url.replace(environment.frontUrl + '/' + this.language + '/', '');
    var gclid = id.indexOf('?')
    var busqueda = (gclid > 0) ? id.slice(0, gclid) : id;
    this.services.getSimplepage(busqueda, this.language).subscribe(data => {
      this.datos = data.data[0];
      if (this.datos.parent && this.datos.parent[0].children) {
        this.datos.parent[0].children.forEach(element => {
          this.itemsParent.push(element)
        });
      }
      if (this.datos.children) {
        this.datos.children.forEach(element => {
          this.itemsParent.push(element)
        });
      }

      if (this.datos.headerSection) {
        this.datos.headerSection.headerSection.headerSectionItem.forEach(element => {
          this.itemsParent.push(element)
        });
      }
      if (this.datos.links) {
        this.styleColor = this.datos.links[0].styleColor;
        this.titleList = this.datos.links[0].title;
        this.links = this.datos.links;
      }

    })
  }

  ngOnInit(): void {
  }
  url(element: any) {
    let url = '';
    let boolExternalLink: any = element.externalLink;
    if (!boolExternalLink) {
      if (element.route) {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/es/' + url;
      }
      if (element.systemTitle) {
        url = element.systemTitle
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/es/' + url;
      }
    } else {
      url = element.route;
    }

    return url;
  }
}
