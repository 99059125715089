import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-buscador-cursos',
  templateUrl: './buscador-cursos.component.html',
  styleUrls: ['./buscador-cursos.component.scss']
})
export class BuscadorCursosComponent  implements OnInit {
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  cursos: any[] = [];
  niveles: any[] = [];
  areas: any[] = [];
  modalidades: any[] = [];
  tematicas: any[] = [];
  resultados: any[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive = '1';
  pageSize = 15;
  spinner = true;
  filtro = false;
  language = "es"
  constructor(public route: ActivatedRoute, private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      tematica: ['',],
      sede: ['',],
      nivel: ['',],
      modalidad: ['',],
      palabras: ['',],
    });

    /*this.servicesGeneral.getPosgrados().subscribe(data => {

      this.resultados = [];
      this.cursos = [];
      this.areas = data.data[0].area;
      this.regiones = data.data[0].region;
      this.niveles = data.data[0].careerType;
      this.modalidades = data.data[0].modalidad;
      this.spinner = false;

    })*/
  }


  ngOnInit(): void {
  }


  filtrar(event) {
    this.spinner = true;
    var tematica = this.buscador.get('tematica').value;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var modalidad = this.buscador.get('modalidad').value;
    var palabras = this.buscador.get('palabras').value;
    var resultados = document.getElementById("resultados");

    /*this.servicesGeneral.getPosgrados(sede, nivel, area, palabras).subscribe(data => {
      let aux = [];
      this.resultados = [];
      this.cursos = [];
      if (sede == '' && nivel == '' && area == '' && modalidad == '' && palabras == '') {
        this.resultados = [];
        this.filtro = false
        resultados.style.display = 'none';

      } else {
        this.filtro = true
        resultados.style.display = 'block';
        this.cambiarPagina(1)
      }
    })*/
  }
  cambiarPagina(page) {
    var modalidad = this.buscador.get('modalidad').value;
    var palabra = this.buscador.get('palabras').value;
    this.pages = [];
    let resultadoFiltrado = this.filtros(modalidad);
    this.resultados = resultadoFiltrado.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.cursos.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }
    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;

  }
  filtros(modalidad: string): any[] {
    let listaFiltrada = this.cursos;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => this.processText(r.modalidad) == this.processText(modalidad))
    }

    return listaFiltrada;
  }
  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }
  ngOnDestroy() {

  }
}
