<div class="container">
    <div class="row">
        <div class="col-lg-4 ">
            <h2 class='azul'>Seguinos en redes</h2>
            <h3 class='grey'>
                <a style='cursor:pointer;color: #007bff;' (click)='windowOpen("https://www.instagram.com/posgradosuca")'>
                    <img onerror="this.src='./assets/img/default.png'" class='icon' src="../../../../../assets/img/instagram-logo-circle.png" alt="">
                    /posgradosuca
                </a>
            </h3>

            <h3 class='grey'>
                <a style='cursor:pointer;color: #007bff;' (click)='windowOpen("https://www.facebook.com/UCAPosgrados")'>
                    <img onerror="this.src='./assets/img/default.png'" class='icon' src="../../../../../assets/img/facebook-circle.png" alt="">
                    /UCAPosgrados
                </a>
            </h3>


        </div>
        <div class="col-lg-4 ">
            <h2 class='azul' style='margin-bottom:5px;'>Descarga la app</h2>
            <div style='display: flex;' class='text-center'>
                <div class='w-40 text-center '>
                    <img style='width: 90%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/qr-appStore.png" alt="">
                    <img style='width: 80%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/app-store.png"
                        alt="">

                </div>
                <div class='w-40 text-center '>
                    <img style='width: 90%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/qr-googlePlay.png" alt="">
                    <img style='width: 80%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/google-play.png"
                        alt="">
                </div>

            </div>

        </div>
        <div class="col-lg-4 text-center">
            <h2 class='azul'>Contactate con nosotros</h2>
            <a (click)='openModal()' class="btn btn-primary btn-contacto">Contacto</a>

        </div>
    </div>
</div>