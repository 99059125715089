import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-todas-las-carreras',
  templateUrl: './todas-las-carreras.component.html',
  styleUrls: ['./todas-las-carreras.component.scss']
})
export class TodasLasCarrerasComponent implements OnInit {

  datos:any;
  datosCarreras:any[]=[];
  universidades:any[]=[];
  items:any[]=[];
  language: any;
  constructor(private services:GeneralService,private route:ActivatedRoute) { 
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.services.getCarreras(1,'','').subscribe(data=>{
      this.universidades=data.data[0].region;
      for (const key in data.data[0].region) {
        this.services.getCarreras(data.data[0].region[key].id,'','').subscribe(data=>{
            this.datosCarreras[key]=data.data[0].career;
            this.datosCarreras[key].sort(function (a, b) {
              if (a.title > b.title) {
                return 1;
              }
              if (a.title < b.title) {
                return -1;
              }
              // a must be equal to b
              return 0;
            })
        })
      }
    })
    // this.services.getCarreras(2,'','').subscribe(data=>{
    //   console.log(data);
    // })
    // this.services.getCarreras(3,'','').subscribe(data=>{
    //   console.log(data);
    // })
    // this.services.getCarreras(4,'','').subscribe(data=>{
    //   console.log(data);
    // })
    //se quita por que piden que quieren mostrar carreras no facultades
    // this.services.getSimplepage('facultades-e-institutos',this.language).subscribe(data=>{
    //     this.datos=data.data[0];
    //     this.items=this.datos.links;
    // })
  }

  ngOnInit(): void {
  }
  abrirPanel(i){
    if( $('#collapse'+i).hasClass('show')){
      $('.show').addClass('show');
      $('#collapse'+i).removeClass('show');
    }else{
      $('.show').removeClass('show');
      $('#collapse'+i).addClass('show');
    }
  

  }
}
