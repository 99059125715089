
 
      <section class="subheader doble-m">
        <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="datos.path" >
        <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="datos.pathMobile" >   
        <div class="texto-s container">
          <h1 class="azul">{{datos.title}}</h1>      
        </div> 
 

  </section>
  <section class="agsec1" *ngIf='datos.body'>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div id='body'></div>
        </div>
      </div>
    </div>
  </section>
  <section class="agsec1">
    <div class="container" >
      <div class="row">
        <div class="col-lg-8">
          
          <div  *ngFor='let link of links'>
            <h2 [style.color]="styleColor" class='mt-3'>{{link.title}}</h2>
            <div class="list-general">
               <div *ngFor='let item of link.linkItems' class="list-general-item">
                
                 <a  *ngIf='!item.child' class='pointer' [href]="url(item)"  > 
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-record" viewBox="0 0 16 16">
                     <path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10z"/>
                   </svg>
                    {{item.title}}
                </a>

                 <a  class='pointer ml-5' *ngIf='item.child'  [href]="url(item)"  > 
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-record" viewBox="0 0 16 16">
                     <path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10z"/>
                   </svg>
                   {{item.title}}
                </a>
                <br>
          </div>
          
       </div>
          </div>
           
      </div>
      <div class="col-lg-4">
        <ul class="list-group">
            <li class="list-group-item active ">Más información
            </li>
 
            <li *ngFor='let item of itemsParent' class="list-group-item "><a [href]="url(item)">{{item.title}}</a></li>

          </ul> 
         </div>
      </div>
    </div>
  </section>


