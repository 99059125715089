<section class="subheader doble-m min" *ngIf="datos">
  <img onerror="this.src='./assets/img/default.png'" *ngIf='datos.path' class="img-title w-100 desk img-desk" [src]="datos.path">
  <img onerror="this.src='./assets/img/default.png'" *ngIf='datos.pathMobile' class="img-title w-100 mob img-mob"
    [src]="datos.pathMobile">
  <div class="{{classLettersTitle}} container card-title" id="card-title">
    <div class="float-left title-section">
      <h5 id="title-font" class="blanco">{{datos.title}}</h5>
      <h5 id="title-font-hidden"></h5>
      <div class="vr mb-3"></div>
      <span class='text-center link-section'>
        <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-university"
          src="./assets/img/university-solid.png" alt="">
        <a [href]="item.link" class="blanco" *ngFor='let item of region'>{{item.title}} </a>
      </span>
    </div>
    <div class="float-right buttom-section" *ngIf="venue!='facultad-de-teologia' &&  carrer!='ingenieria-electronica'">
      <a href="https://inscripcion.uca.edu.ar/" target="_blank" class="fs-26 btn btn-primary ">INSCRIBITE</a>
    </div>
  </div>
</section>
<section class="facsec2 min section-primary " *ngIf="datos" id="section-primary ">
  <div class="container ">
    <div class="row">
      <div class="col-lg-12">
        <app-porque-estudiar-en-uca [htmlEstudiar]="htmlEstudiar"></app-porque-estudiar-en-uca>
      </div>
    </div>
  </div>
</section>
<section class="facsec2  min" *ngIf="planData && datos">
  <div class="container mt-3">
    <div class="row">
      <div class="col-lg-12">
        <app-plan-de-estudio [planData]="planData" [titlesPlanData]="titlesPlanData"
          [buttonStudyPlan]="datos.buttonStudyPlan"></app-plan-de-estudio>
      </div>
    </div>
  </div>
</section>

<section class="facsec2  min" *ngIf="datos && datos.experience_button">
  <div class="container mt-3">
    <div class="row">
      <div class="col-lg-12">
        <div id="experiencia-uca">
          <div class="float-left title-section">
            <h1 class="blanco">
              <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-date"
                src="./assets/img/calendar.png" alt="">
              Experiencia UCA
            </h1>
          </div>
          <div class=" buttom-section-red">
            <a [href]="datos.experience_button" target="_blank" class="fs-26 btn btn-primary button-red ">ANOTATE</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>


<section class="facsec2  min" *ngIf="datos">
  <div class="container mt-3">
    <div class="row">
      <div class="col-lg-12">
        <app-perfil-profesional [htmlPerfil]="htmlPerfil"></app-perfil-profesional>
      </div>
    </div>
  </div>
</section>
<section class="facsec2  min" *ngIf='datos && datos.benefitsButtons'>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <section class="logsec3">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <app-icons [data]='datos.benefitsButtons'></app-icons>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
<section class="facsec2 min" id='container-video' *ngIf="iframe">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>Conocenos</h2>
        <p *ngIf='iframe' style="display: flex;">
          <iframe width='900' height='500' [src]="iframe" frameborder="0" allowfullscreen></iframe>
        </p>
      </div>

    </div>
  </div>
</section>


<section class="facsec2  min" *ngIf="datos">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <app-list-desplegable *ngIf='datos.otherCareer' [items]='datos.otherCareer'
          [urlExtra]='"/facultades/"+seccion+"/carrera-de-grado/"' title='Carreras relacionadas
          '></app-list-desplegable>
      </div>
    </div>
  </div>
</section>
<section class="facsec2 min " *ngIf="datos">
  <div class="container" *ngIf="carrer!='ingenieria-electronica'">
    <div class="row">
      <div class="col-lg-12" id='contacto'>
        <app-form-contacto [carrer]='carrer' type="posgrados" [venue]='venue' [tel]='carreraTrue'></app-form-contacto>
      </div>
    </div>
  </div>
</section>