<section class="subheader  doble-m">
    <img onerror="this.src='./assets/img/default.png'" class=" img-desk img-title w-100 desk img-desk" src=".">
    <img onerror="this.src='./assets/img/default.png'" class=" img-mob img-title w-100 mob img-mob" src=".">

    <!--<img onerror="this.src='./assets/img/default.png'"  class=" img-desk img-title w-100 desk img-desk" [src]="datos.path">
    <img onerror="this.src='./assets/img/default.png'"  class=" img-mob img-title w-100 mob img-mob" [src]="datos.pathMob">-->
</section>
<section class="logsec1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-buscador-cursos></app-buscador-cursos>
            </div>
        </div>
    </div>
</section>

<section class="logsec1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12  ">
                <div class="container-responsive">
                    <div class="container-facultad ">
                        <div class="card-facultad green d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/eco.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">CIENCIAS AGRARIAS</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad violet d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/grafico.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">CIENCIAS ECONÓMICAS Y NEGOCIOS</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad greenwater d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/simbolo-caduceo.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">CIENCIAS MÉDICAS</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad brown d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/redes.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">CIENCIAS SOCIALES</span>
                        </div>
                    </div>
                    
    
                    <div class="container-facultad">
                        <div class="card-facultad dark-green d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/biblia.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">DERECHO CANÓNICO</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad red d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/biblia.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">DERECHO</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad brown d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/libro-de-texto.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">EDUCACIÓN</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad sky-blue d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/pluma-pluma.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">FILOSOFÍA Y LETRAS</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad blue d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/idioma.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">IDIOMAS</span>
                        </div>
                    </div>
    
    
                    <div class="container-facultad">
                        <div class="card-facultad green d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/industrial.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">INGENIERÍA</span>
                        </div>
                    </div>
    
    
                    <div class="container-facultad">
                        <div class="card-facultad blue d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/linguistica.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">INGLES</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad blue d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/libro-abierto.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">INSTITO DE CULTURA UNIVERSITARIA</span>
                        </div>
                    </div>
                    <div class="container-facultad">
                        <div class="card-facultad purple d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/familia.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">INSTITO PARA EL MATRIMONIA Y FAMILIA</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad yellow d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/musica.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">MUSICA</span>
                        </div>
                    </div>
    
    
                    <div class="container-facultad">
                        <div class="card-facultad magenta d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/cerebro.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">PSICOLOGÍA Y PSICOPEDAGOGÍA</span>
                        </div>
                    </div>
    
    
                    <div class="container-facultad">
                        <div class="card-facultad green d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/matraz.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">QUIMÍCA</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad yellow d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/teatro.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">TEATRO</span>
                        </div>
                    </div>
    
                    <div class="container-facultad">
                        <div class="card-facultad violet d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="container-icon-2">
                                <div class="container-icon">
                                    <img src="./assets/img/cristiandad.png" alt="">
                                </div>
                            </div>
                            <span class="title-facultad">TEOLOGÍA</span>
                        </div>
                    </div>
                </div>
      

            </div>
        </div>
    </div>
</section>