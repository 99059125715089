import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursos-listado',
  templateUrl: './cursos-listado.component.html',
  styleUrls: ['./cursos-listado.component.scss']
})
export class CursosListadoComponent implements OnInit {
  datos:any;
  constructor() { }

  ngOnInit(): void {
  }

}
