import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { title } from 'process';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-buscador-posgrados',
  templateUrl: './buscador-posgrados.component.html',
  styleUrls: ['./buscador-posgrados.component.scss']
})
export class BuscadorPosgradosComponent implements OnInit {
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  carreras: Carrera[] = [];
  niveles: Carrera[] = [];
  areas: Carrera[] = [];
  modalidades: Carrera[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive = '1';
  pageSize = 15;
  spinner = true;
  filtro = false;
  language = "es"
  constructor(public route: ActivatedRoute, private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      modalidad: ['',],
      palabras: ['',],
    });

    this.servicesGeneral.getPosgrados().subscribe(data => {

      this.resultados = [];
      this.carreras = [];
      this.areas = data.data[0].area;
      this.regiones = data.data[0].region;
      this.niveles = data.data[0].careerType;
      this.modalidades = data.data[0].modalidad;
      this.spinner = false;

    })
  }


  ngOnInit(): void {
  }


  filtrar(event) {
    this.spinner = true;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.buscador.get('area').value;
    var modalidad = this.buscador.get('modalidad').value;
    var palabras = this.buscador.get('palabras').value;
    var resultados = document.getElementById("resultados");

    this.servicesGeneral.getPosgrados(sede, nivel, area, palabras).subscribe(data => {
      let aux = [];
      this.resultados = [];
      this.carreras = [];
      if (data.data[0].career) {

        data.data[0].career.forEach(element => {
          let inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          let title = element.title + ' | Inicio: ' + inicio;
          let date = (element.inicio) ? new Date(element.inicio.date) : null;
          let modalidadBD = (element.modalidad) ? element.modalidad : 'Sin modalidad';
          aux.push(new Carrera(element.id, title, element.linkCareer, date, modalidadBD))
        });
        this.carreras = aux.sort(function (a, b) { return (a.fecha && b.fecha) ? a.fecha.getTime() - b.fecha.getTime() : 0 });
        console.log(this.carreras)
      }
      if (sede == '' && nivel == '' && area == '' && modalidad == '' && palabras == '') {
        this.resultados = [];
        this.filtro = false
        resultados.style.display = 'none';

      } else {
        this.filtro = true
        resultados.style.display = 'block';
        this.cambiarPagina(1)
      }
    })
  }
  cambiarPagina(page) {
    var modalidad = this.buscador.get('modalidad').value;
    var palabra = this.buscador.get('palabras').value;
    this.pages = [];
    let resultadoFiltrado = this.filtros(modalidad);
    this.resultados = resultadoFiltrado.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.carreras.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }
    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;

  }
  filtros(modalidad: string): any[] {
    let listaFiltrada = this.carreras;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => this.processText(r.modalidad) == this.processText(modalidad))
    }

    return listaFiltrada;
  }
  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }
  ngOnDestroy() {

  }
}
