import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { data } from 'jquery';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-cursos-proximos',
  templateUrl: './cursos-proximos.component.html',
  styleUrls: ['./cursos-proximos.component.scss']
})
export class CursosProximosComponent implements OnInit {
  valor: string;
  count: number;

  numberActiveTodos = '1';
  pagesTodos: any[] = [];

  numberActivePosgrados = '1';
  pagesPosgrados: any[] = [];


  numberActiveEspecializacion = '1';
  pagesEspecializacion: any[] = [];


  numberActiveCursos = '1';
  pagesCursos: any[] = [];


  numberActiveMaestria = '1';
  pagesMaestria: any[] = [];


  numberActiveDoctorado = '1';
  pagesDoctorado: any[] = [];





  pageSize = 15;

  resultadosTodos: any[] = []
  resultadosDoctorado: any[] = []
  resultadosMaestria: any[] = []
  resultadosEspecializacion: any[] = []
  resultadosCursos: any[] = []
  resultadosPosgrados: any[] = []

  resultadosTodosFilter: any[] = []
  resultadosDoctoradoFilter: any[] = []
  resultadosMaestriaFilter: any[] = []
  resultadosEspecializacionFilter: any[] = []
  resultadosCursosFilter: any[] = []
  resultadosPosgradosFilter: any[] = []
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  spinner = true;
  language: string;
  constructor(private servicesGeneral: GeneralService, public route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.servicesGeneral.getPosgrados(null, 'Doctorado').subscribe(res => {
      res.data[0].career.forEach(element => {
        let inicio;
        if (element.inicio) {
          if (new Date() < new Date(element.inicio.date)) {
            inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getDate(), new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          } else {
            inicio = 'Finalizado';
          }
        } else {
          inicio = 'A definir';
        }
        let dateForOrder;
        if (element.inicio) {
          if (new Date() > new Date(element.inicio.date)) {
            dateForOrder = new Date(element.inicio.date).getDate();
          }
        }
        let title = element.title + ' | Inicio: ' + inicio;
        let date = (element.inicio) ? new Date(element.inicio.date) : null;
        if (inicio != "Finalizado") {
          this.resultadosDoctoradoFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
          this.resultadosDoctoradoFilter.sort(this.SortArray);
          this.resultadosTodosFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
        }
      });
      this.cambiarPaginaDoctorado(1, this.resultadosDoctoradoFilter);

    })

    this.servicesGeneral.getPosgrados(null, 'Maestría').subscribe(res => {
      res.data[0].career.forEach(element => {

        let inicio;
        if (element.inicio) {
          if (new Date() < new Date(element.inicio.date)) {
            inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getDate(), new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          } else {
            inicio = 'Finalizado';
          }
        } else {
          inicio = 'A definir';
        }
        let dateForOrder;
        if (element.inicio) {
          if (new Date() > new Date(element.inicio.date)) {
            dateForOrder = new Date(element.inicio.date).getDate();
          }
        }
        let title = element.title + ' | Inicio: ' + inicio;
        let date = (element.inicio) ? new Date(element.inicio.date) : null;

        if (inicio != "Finalizado") {
          this.resultadosMaestriaFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
          this.resultadosMaestriaFilter.sort(this.SortArray);
          this.resultadosTodosFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
        }
      });
      this.cambiarPaginaMaestria(1, this.resultadosMaestriaFilter);

    })


    this.servicesGeneral.getPosgrados(null, 'Especialización').subscribe(res => {
      res.data[0].career.forEach(element => {
        let inicio;
        if (element.inicio) {
          if (new Date() < new Date(element.inicio.date)) {
            inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getDate(), new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          } else {
            inicio = 'Finalizado';
          }
        } else {
          inicio = 'A definir';
        }
        let dateForOrder;
        if (element.inicio) {
          if (new Date() > new Date(element.inicio.date)) {
            dateForOrder = new Date(element.inicio.date).getDate();
          }
        }
        let title = element.title + ' | Inicio: ' + inicio;
        let date = (element.inicio) ? new Date(element.inicio.date) : null;
        if (inicio != "Finalizado") {
          this.resultadosTodosFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
          this.resultadosEspecializacionFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
          this.resultadosEspecializacionFilter.sort(this.SortArray);
        }
      });
      this.cambiarPaginaEspecializacion(1, this.resultadosEspecializacionFilter);

    })

    this.servicesGeneral.getPosgrados(null, "Curso Online").subscribe(res => {
      res.data[0].career.forEach(element => {
        let inicio;
        if (element.inicio) {
          if (new Date() < new Date(element.inicio.date)) {
            inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getDate(), new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          } else {
            inicio = 'Finalizado';
          }
        } else {
          inicio = 'A definir';
        }
        let dateForOrder;
        if (element.inicio) {
          if (new Date() < new Date(element.inicio.date)) {
            dateForOrder = new Date(element.inicio.date).getDate();
          }
        }
        let title = element.title + ' | Inicio: ' + inicio;
        let date = (element.inicio) ? new Date(element.inicio.date) : null;
        if (inicio != "Finalizado") {
          this.resultadosTodosFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder));
          this.resultadosCursosFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder));
          this.resultadosCursosFilter.sort(this.SortArray);
        }
      });
      this.cambiarPaginaCursos(1, this.resultadosCursosFilter);

    })
    this.servicesGeneral.getPosgrados(null, "Curso de Posgrado").subscribe(res => {
      res.data[0].career.forEach(element => {

        let inicio;
        if (element.inicio && element.inicio != undefined) {
          if (new Date() < new Date(element.inicio.date)) {
            inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getDate(), new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          } else {
            inicio = 'Finalizado';
          }
        } else {
          inicio = 'A definir';
        }
        let dateForOrder;
        if (element.inicio) {
          if (new Date() > new Date(element.inicio.date)) {
            dateForOrder = new Date(element.inicio.date).getDate();
          }
        }
        let title = element.title + ' | Inicio: ' + inicio;
        let date = (element.inicio) ? new Date(element.inicio.date) : null;
        //let datoForOrder = (element.inicio) ? new Date(element.inicio.date) : new Date(element.inicio.date);
        if (inicio != "Finalizado") {
          this.resultadosPosgradosFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
          this.resultadosPosgradosFilter.sort(this.SortArray);
          // this.resultadosPosgradosFilter.sort(this.SortArrayNull);
          this.resultadosTodosFilter.push(new Carrera(element.id, title, element.linkCareer, date, dateForOrder))
        }
      });


      this.resultadosTodosFilter.sort(this.SortArray);
      this.cambiarPaginaPosgrados(1, this.resultadosPosgradosFilter);

    })

    this.resultadosTodosFilter=this.resultadosTodosFilter.sort(function (a, b) { return (a.fecha && b.fecha) ? a.fecha.getTime() - b.fecha.getTime() : 0 });

  }

  ngOnInit(): void {

  }
  SortArray(a, b) {

    // equal items sort equally
    if (a.fecha === b.fecha) {
      return 0;
    }
    // nulls sort after anything else
    else if (a.fecha === null) {
      return 1;
    }
    else if (b.fecha === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else {
      return a.fecha < b.fecha ? -1 : 1;
    }
    // if descending, highest sorts first
    // else { 
    //   return a < b ? 1 : -1;
    // }
  }
  SortArrayNull(x, y) {
    if (!(x.fecha)) { return 1; }
    // if (x.fecha < y.fecha) {return -1;}
    // if (x.fecha > y.fecha) {return 1;}

    return 0;
  }

  cambiarPaginaTodos(page, lista) {
    this.pagesTodos = [];
    this.resultadosTodos = this.resultadosTodosFilter.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.resultadosTodosFilter.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pagesTodos.push(i + 1);
    }
    this.numberActiveTodos = page;
    page = (page == '...') ? (this.numberActiveTodos) : page;
    this.spinner = false;
  }


  cambiarPaginaMaestria(page, lista) {

    this.pagesMaestria = [];
    this.resultadosMaestria = lista.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(lista.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pagesMaestria.push(i + 1);
    }
    this.numberActiveMaestria = page;
    page = (page == '...') ? (this.numberActiveMaestria) : page;
    this.spinner = false;
    this.cambiarPaginaTodos(1, this.resultadosTodosFilter);

  }

  cambiarPaginaDoctorado(page, lista) {
    this.pagesDoctorado = [];
    this.resultadosDoctorado = lista.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(lista.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pagesDoctorado.push(i + 1);
    }
    this.numberActiveDoctorado = page;
    page = (page == '...') ? (this.numberActiveDoctorado) : page;
    this.spinner = false;
    this.cambiarPaginaTodos(1, this.resultadosTodosFilter);

  }


  cambiarPaginaEspecializacion(page, lista) {
    this.pagesEspecializacion = [];
    this.resultadosEspecializacion = lista.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(lista.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pagesEspecializacion.push(i + 1);
    }
    this.numberActiveEspecializacion = page;
    page = (page == '...') ? (this.numberActiveEspecializacion) : page;
    this.spinner = false;
    this.cambiarPaginaTodos(1, this.resultadosTodosFilter);

  }


  cambiarPaginaCursos(page, lista) {
    this.pagesCursos = [];
    this.resultadosCursos = lista.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(lista.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pagesCursos.push(i + 1);
    }
    this.numberActiveCursos = page;
    page = (page == '...') ? (this.numberActiveCursos) : page;
    this.spinner = false;
    this.cambiarPaginaTodos(1, this.resultadosTodosFilter);

  }

  cambiarPaginaPosgrados(page, lista) {
    this.pagesPosgrados = [];
    this.resultadosPosgrados = lista.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(lista.length / this.pageSize)
    for (let i = 0; i < cant; i++) {
      this.pagesPosgrados.push(i + 1);
    }
    this.numberActivePosgrados = page;
    page = (page == '...') ? (this.numberActivePosgrados) : page;
    this.spinner = false;
    this.cambiarPaginaTodos(1, this.resultadosTodosFilter);

  }


  getInicio(dia: number, mes: number, año: number) {
    return dia + ' ' + this.meses[mes] + ' ' + año;
  }




}
