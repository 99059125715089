<section class="facsec2 min" *ngIf="type=='ingreso' || type=='posgrados'">
    <div class="container">
        <div class="row">
            <h1 class="title-page">
                Resultados para "{{type}}" con la palabra clave "{{palabra}}"
            </h1>
        </div>
        <div class="row" *ngFor="let item of lista">
            <div class="card-border d-flex flex-sm-row flex-column justify-content-between">
                <div class="d-flex flex-column">
                    <h1 class="title" >
                        <a [href]="'/'+language + item.linkCareer">
                            {{item.title}}
                        </a>
                    </h1>

                    <span class="label">
                        <img onerror="this.src='./assets/img/default.png'" class='icon' id="icon-date"
                            src="./assets/img/time-and-calendar.png" alt="">
                        Inicio :
                        <span class="value" *ngIf="item.inicio">
                            {{item.inicio.date | date:'d/M/yy'}}</span>
                        <span class="value" *ngIf="!item.inicio">-</span>
                    </span>
                    <span class="label">
                        Duración :
                        <span class="value" *ngIf="item.duracion">{{item.duracion}}</span>
                        <span class="value" *ngIf="!item.duracion">-</span>
                    </span>
                    <span class="label">
                        Modalidad :
                        <span class="value" *ngIf="item.modalidad">{{item.modalidad}}</span>
                        <span class="value" *ngIf="!item.modalidad">-</span>
                    </span>
                </div>
                <div class="container-btn">
                    <button class="btn btn-ver" [routerLink]="'/'+language + item.linkCareer">
                        Ver carrera
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf=' lista.length == 0 && !spinner ' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </div>
</section>