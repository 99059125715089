export class Carrera{
    id:number;
    nombre:string;
    link:string;
    fecha?:Date;
    modalidad?:string;
    dateForOrder?:Date;
    constructor(id:number, nombre:string,link:string,fecha?: Date,modalidad?:string,dateForOrder?:Date){
     this.id=id;
     this.nombre=nombre;
     this.link=link;
     this.fecha=fecha;
     this.modalidad=modalidad;
     this.dateForOrder=dateForOrder;
    }
}