import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { RegistroCard2 } from 'src/app/shared/models/registroNoticia';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cards-general',
  templateUrl: './cards-general.component.html',
  styleUrls: ['./cards-general.component.scss']
})
export class CardsGeneralComponent implements OnInit {

  @Input() id: string;
  @Input() styleTitle = 'azul';
  @Input() title: string;
  @Input() type: string;
  @Input() vanue = false;
  @Input() numberCards = 4;
  @Input() data: any[];
  @Input() urlExtra = '';

  @Input() background = '#ffffff';
  cards: RegistroCard2[] = [];
  cardsDesk: RegistroCard2[] = [];
  noticiaActive: string;
  language: any;
  cargado = false;
  constructor(public route: ActivatedRoute, private router: Router) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

  }

  ngOnInit(): void {

    if (this.background == '#c0c0c0') {
      this.styleTitle = "azul";
      this.background = '#ffffff';


    } else if (this.background == '#ffffff') {
      this.styleTitle = "azul";
    } else if (this.background != '#003a73') {
      this.styleTitle = "azul";
      this.background = '#ffffff';
    }
    if (this.data) {
      this.noticiaActive = this.data[0].id;

      this.data.forEach(element => {
        let registro = new RegistroCard2();
        registro.title = element.title;
        registro.foto = element.path;
        registro.id = element.id;
        registro.categoria = element.categoryTitle;
        registro.link = element.path;

        element.systemTitle = this.formatStandar(element.systemTitle);
        if (element.typeEntity == 'Post') {
          registro.systemTitle = '/' + this.language + '/noticias/' + element.systemTitle;
        } else if (element.typeEntity == "SimplePage") {
          registro.systemTitle = '/' + this.language + '/' + element.systemTitle;
        } else {
          registro.systemTitle = '/' + this.language + '/' + element.systemTitle;
        }
        this.cards.push(registro);
      });

      //  this.cardsDesk=this.cards.slice(0,6)
      this.cardsDesk = this.cards
    }

  }
  cambiarSlider(i: number) {
    this.noticiaActive = this.cards[i].id;

  }

  addCards() {
    this.cargado = true;
    this.cardsDesk = this.cards;
  }

  removeCards() {
    this.cargado = false;
    this.cardsDesk = this.cards.slice(0, 6)
  }

  formatStandar(systemTitle) {
    if (systemTitle[0] == '/') {
      return systemTitle.slice(1, systemTitle.length);;
    }
    return systemTitle;
  }
}
